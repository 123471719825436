<template>
	<div style="background:#f8f8f8;">
		 <InPageImage></InPageImage>
		<div class="wrapper baseContent">
			 <Breadcrumb :crumbsList="pathList"></Breadcrumb>
			<div class="padding-top-20" style="padding-top:30px;">
				<div class="text-center base-font-30 text-bold">{{curMenu.name}}</div>
			</div>
			<div style="padding:40px 0;">
				<div class="wrapper">
					<div>
						<el-row :gutter="15">
							<el-col :span="12" v-for="(item,index) in list" :key="index" >
								<div class="caseItemBox text-left bg-white" @click="goNewsDetails(item.id)">
									<div class="caseImage">
										<el-image :src="item.image" fit="cover" ></el-image>
									</div>
									<div class="itemContent">
									 <div class="base-font-26 ellipsis text-center">{{item.title}}</div>
									<!-- <div class="base-font-13 text-666">{{item.source}}<span style="float: right">{{item.showTime}}</span></div> -->
										</div>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
        <div v-if="hasNextPage" style="text-align: center;">
          <div @click="more" class="text-center pointer moreBtn align-center">加载更多</div>
        </div>
			</div>
		</div>
		
	</div>
</template>

<script>
	import Breadcrumb from '../../components/breadcrumbs.vue'
	import InPageImage from '../../components/inPageImage.vue'
  import {getWzContentPage, menuByUrl} from "@/api/api";
	export default{
		components: {
			Breadcrumb,InPageImage
		},
		data(){
			return{
        leftMenu: {childList: []},
        pathList: [],
        curMenu: {id: ''},
        pageNum: 1,
        pageSize: 9,
        hasNextPage: false,
        list: [],
        kindId: ''
			}
		},
    mounted() {
      var kindId = this.$route.query.kindId;
      var path = this.$route.path + "?kindId=" + kindId;
      this.kindId = kindId;
	  path = path.replaceAll('/?', '?');
      console.log("path:" + path);
      this.menuByUrl(path);
      this.getWzContentPage();
    },
		methods:{
      menuByUrl(path) {
        menuByUrl(1, path).then(res => {
          this.pathList = res.data.pathList;
          this.leftMenu = res.data.leftMenu;
          this.curMenu = res.data.curMenu;
        })
      },
      more(){
        this.pageNum=this.pageNum+1;
        this.getWzContentPage();
      },
      getWzContentPage() {
        getWzContentPage({pageNum: this.pageNum, pageSize: this.pageSize, kindId: this.kindId}).then(res => {
          this.hasNextPage = res.hasNextPage;
          if(this.pageNum==1){
            this.list=[];
          }
          res.data.forEach((obj, index) => {
            obj.showTime = this.$moment(obj.clickTime).format('YYYY-MM-DD');
            this.list.push(obj);
          })
        })
      },
      goNewsDetails(id) {
        this.$router.push({path: '/proDetails?menuId=' + this.curMenu.id + '&id=' + id});
      }
		}
	}
</script>

<style>

.moreBtn{height:46px;width:146px;border-radius:24px;line-height:48px;border:2px solid #e6712c;margin: 0px auto;}
.moreBtn:hover{color:#ffffff;background:#e6712c;}
	.viewProBtn{height:46px;width:146px;border-radius:24px;line-height:48px;border:2px solid rgba(0, 113, 239, 1)}
	.viewProBtn:hover{color:#ffffff;background:rgba(0, 113, 239, 1);}

	.caseItemBox{width:100%;margin-bottom: 30px;cursor: pointer; position:relative;}
	.caseItemBox .viewBtn{width:100%;height:204px;position:absolute;background:rgba(0, 0, 0, 0.1);position: absolute;top:0;left:0;z-index:999;}
	.caseItemBox .viewBtn .bfBtn{width:60px;height:60px;border-radius:50%;background:rgba(0, 0, 0, 0.2);text-align:center;padding-top:15px;box-sizing: border-box;margin:72px auto;}
	.caseItemBox .viewBtn .bfBtn .el-image{width:30px;height:30px;margin:0 auto}
	.caseItemBox .caseImage{widht:100%;height:204px;overflow: hidden;}
	.caseItemBox .caseImage .el-image{
		width:100%;height:204px;
		transition: transform 0.7s;
		-webkit-transition: transform 0.7s;
		}
	.caseItemBox .itemContent {
	
		padding:20px 20px;
		box-sizing: border-box;
		line-height:32px;
	}
	.caseItemBox:hover {
		box-shadow: 0 0 30px #dddddd;
	}
	
	.caseItemBox:hover .caseImage .el-image{
		 transform:scale(1.5);
		-webkit-transform:scale(1.5);
		-ms-transform:scale(1.5);		
		-moz-transform:scale(1.5);		
		-o-transform:scale(1.5);
		
		transition: transform 0.7s;
		-webkit-transition: transform 0.7s;
	}
	.homeVideoModal {
	    background: rgba(0, 0, 0, 0.3);
	}
	
	.homeVideoModal .el-dialog {
	    background: none;
	    box-shadow: none;
	}
	
	.homeVideoModal .el-dialog--center .el-dialog__body {
	    padding: 0;
	}
	
	.homeVideoModal .el-dialog__headerbtn {
	    right: 0;
	    top: 0;
	}
	
	.homeVideoModal .el-dialog__headerbtn .el-dialog__close {
	    color: #FFFFFF;
	    font-size: 28px;
	}
</style>